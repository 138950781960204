// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("/opt/build/repo/src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-js": () => import("/opt/build/repo/src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("/opt/build/repo/src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-meet-us-js": () => import("/opt/build/repo/src/pages/meet-us.js" /* webpackChunkName: "component---src-pages-meet-us-js" */),
  "component---src-pages-wine-js": () => import("/opt/build/repo/src/pages/wine.js" /* webpackChunkName: "component---src-pages-wine-js" */)
}

